import { useEffect, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { RingLoader } from 'react-spinners';

const FileUpload = (props) => {
    const [temp,setTemp] = useState(null)
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const newFile = acceptedFiles[0]
    const {file,setFile} = props
    
    useEffect(()=>{
        if (newFile) {
            setFile(newFile)
        }
    },[newFile])

    // const fileRender = (file) => {
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             setTemp(reader.result)
    //         };
    //     }
    // }

    // useEffect(()=>{
    //     if (file) {
    //         fileRender(file)
    //     }
    // },[file])

    
    return(
        <section className="container">
            <div style={{cursor:'pointer'}} {...getRootProps({className: 'dropzone'})}>
                <input onChange={(e)=>{}} name="logo" multiple={false}  {...getInputProps()} />
                {
                    props.loading || !props.img ?
                    <RingLoader color="#313860" size={80} />
                    :
                    <img className='file' src={props.img} />
                }
            </div>
        </section>
    )
}

export default FileUpload;