import { Col, Container, Form, Row } from "react-bootstrap"
import { ThemeInput, ThemeButton} from "../../components"
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../slices/authSlice";
import { useEffect, useState } from "react";
import { alert } from "../../slices/alertSlice";
import Logo from "../../assets/images/assets/logo.svg"

const Login = () => {
    const [loading,setLoading] = useState(false);
    const [sms,setSms] = useState(false);
    const [phone,setPhone] = useState("");
    const dispatch = useDispatch();
    
    const submitLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.target);
        axios.post("/admin/login",{
            phone:phone,
            password:data.get("code"),
            phone
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then((res) => {
            const data = res.data.data;
            dispatch(login(data))
            localStorage.setItem("user",JSON.stringify(data))
            dispatch(alert({message:"Giriş Başarılı!",status:"success",show:true}))
            setLoading(false);
        }
        ).catch((err) => {
            if (err.response.status === 400) {
                dispatch(alert({message:err.response.data.data,status:"error",show:true}))
            }
            else{
                dispatch(alert({message:"Giriş Başarısız!",status:"error",show:true}))
            }
            setLoading(false);
        });
    }

    const submitSms = (e) => {
        const data = new FormData(e.target);
        e.preventDefault();
        setPhone(data.get("phone"));
        axios.post("/auth/verify-phone",{
            phone:data.get("phone"),
            process:"login"
        })
        .then((res) => {
            setSms(true);
            dispatch(alert({message:"Sms Gönderildi",status:"success",show:true}))
        })
        .catch(err=>{
            console.log(err);
            if (err?.response?.status === 400) {
                dispatch(alert({message:err.response.data.data,status:"error",show:true}))
            }
            else{
                dispatch(alert({message:"Sms Gönderilemedi!",status:"error",show:true}))
            }
        })
    }

    useEffect(() => {
        document.getElementById("resetForm")?.reset();
    }, [sms])

    return (
        <Container fluid className="fluid login-bg-img">
            <div className="login-container">
            <img style={{width:"100%"}} src={Logo} alt="" />
            {
                sms ? 
                <Form onSubmit={submitLogin} id="resetForm" className="loginForm">
                    <h1 className="loginText">
                        GİRİŞ YAPIN
                    </h1>
                    <ThemeInput
                        title="Kod *"
                        description="Telefonunuza gelen kodu giriniz"
                        placeholder="Kodu giriniz"
                        type="text"
                        name="code"
                        defaultValue=""
                        className="loginInput"
                        rows={13}
                    /> 
                    <ThemeButton centered loading={loading} title={"Giriş Yap"} type="submit" />
                </Form>
                :
                <Form onSubmit={submitSms} className="loginForm">
                    <h1 className="loginText">
                        GİRİŞ YAPIN
                    </h1>
                    <ThemeInput
                        title="Telefon *"
                        description="Telefon numaranızı giriniz"
                        placeholder="Telefon numaranızı giriniz"
                        type="text"
                        name="phone"
                        className="loginInput"
                        rows={13}
                    /> 
                    <ThemeButton centered loading={loading} title={"Sms gönder"} type="submit" />
                </Form>
            }
            </div>
        </Container>
    )
}

export default Login