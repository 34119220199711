import { Col, Container, Row } from "react-bootstrap"
import { ThemeButton } from "."
import TableIcon from "../assets/images/assets/table.png"

const TableCard = (props) => {
    return(
        <Container fluid className="table-card">
            <Row>
                <Col md={12} className="mb-5">
                    <img alt="Bambu Games" src={TableIcon} className="icon" />
                </Col>
                <Col md={12} className="d-flex flex-column mb-1">
                    <span className="title">Masa Adları</span>
                    <span className="value">{props.name}</span>
                </Col>
                <Col md={12} className="d-flex flex-column mb-1">
                    <span className="title">Toplam Harcanan Kredi</span>
                    <span className="value">{props.credit}</span>
                </Col>
                {/* <Col className="d-flex flex-column mb-1">
                    <span className="title">Aktif Olma Süresi</span>
                    <span className="value">{props.time}</span>
                </Col> */}
                <Col md={12}>
                    <span className="title">Aktif/Pasif</span>
                    <ThemeButton loading={props.loading} fullWidth={true} title={props.status} />
                </Col>
                
            </Row>
        </Container>
    )
}

export default TableCard;