import { Col, Container, Row, Table } from "react-bootstrap"
import {  Money, PendingMoney,Table as TableIcon } from "../assets/images/assets/icons"
import ThemeCard from "../components/card"
import ThemeContainer from "../components/ThemeContainer"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { backend_host, creditAmount } from "../helper"
import { PulseLoader } from "react-spinners"

const Home = () => {
    const user = useSelector((state)=>state.auth.user)
    const [loading,setLoading] = useState(false);

    const [table,setTable] = useState();
    const [active_tables,setActiveTables] = useState();
    const [games,setGames] = useState([]);
    const [activeHour,setActiveHour] = useState();
    const [dailyIncome,setDailyIncome] = useState();
    const [totalPlayer,setTotalPlayer] = useState();
    const [dailyPlayer,setDailyPlayer] = useState();
    const [nextPaymentDate,setNextPaymentDate] = useState();
    const [totalCredit,setTotalCredit] = useState();
    const [totalGiftCredit,setTotalGiftCredit] = useState();
    const [dailyGiftCredit,setDailyGiftCredit] = useState();

    const dateFormat = (date) => {
        const d = new Date(date);
    
        // Saati ve dakikayı çift haneli göster
        const formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${padZero(d.getHours())}:${padZero(d.getMinutes())}`;
    
        return formattedDate;
    };
    
    // Bir sayıyı iki haneli olarak formatlamak için yardımcı fonksiyon
    const padZero = (num) => {
        return num.toString().padStart(2, '0');
    };

    useEffect(()=>{
        setLoading(true);
         axios.post("/admin/home-info",{
            headers:{
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setTotalGiftCredit(res.data.giftGames[0].total_credit);
            setTotalCredit(res.data.paidGames[0].total_credit);
            setTable(res.data.tables[0]);
            setActiveTables(res.data.active_tables);
        }).catch((err)=>{
            console.log(err)
        })

        axios.get("/cafes/infos/home-informations",{
            headers:{
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setLoading(false);
            
            setGames(res.data.active_games)
            setActiveHour(res.data.activeHour._id)
            setDailyIncome(res.data.dailyCredit * creditAmount)
            setTotalPlayer(res.data.totalUserCount)
            setDailyPlayer(res.data.dailyUserCount)
            
            setDailyGiftCredit(res.data.dailyGiftCredit)
            setNextPaymentDate("-")
            

        }).catch((err)=>{
            setLoading(false);
            console.log(err)
        })
    },[])
    return (
        <>
            {
                loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
                    <PulseLoader color={"#313860"} size={20} />
                </div>
                :
                <Container fluid  >
                    <Row>
                        <Col xxl={3} xl={6}>
                            <ThemeCard title="Toplam Hediye" desc="Toplam" value={`${totalGiftCredit * creditAmount}₺`} icon={Money} />
                        </Col>
                        <Col xxl={3} xl={6}>
                            <ThemeCard title="Toplam Ücretli" desc="Toplam"  value={`${totalCredit * creditAmount}₺`} desc2="Hakediş(%50)" value2={`${totalCredit * creditAmount/2}₺`} icon={Money} />
                        </Col>
                        <Col xxl={3} xl={6}>
                            <ThemeCard title="Masalarım" desc="Aktif Masa Sayısı" value={`${table?.trueCount}/${table?.totalCount}`} icon={TableIcon}  linkText={"Tümünü görüntüleyin"} link={"masalar"} />
                        </Col>
                        {/* <Col xxl={3} xl={6}>
                            <ThemeCard title="Bekleyen Ödemeler" desc="Aralık Ödemesi" value="-" icon={PendingMoney} />
                        </Col> */}

                        {/* <Col xxl={6} xl={6}>
                            <Container fluid className="px-0">
                                <Row>
                                    <Col md={6} className="mt-2">
                                        <ThemeContainer title="Günün En Çok Oynanan Saati" desc="En çok aktif olunan saat" children={()=><>
                                            { activeHour == undefined ? "-" : `${activeHour}:00`}
                                        </>} />
                                    </Col>
                                    <Col md={6} className="mt-2">
                                        <ThemeContainer title="Günlük Kazanç" desc="Günlük Masalardan kazanılan miktar" children={()=><>
                                            {dailyIncome == undefined ? "0₺" : `${dailyIncome}₺`}
                                        </>} />
                                    </Col>
                                    <Col md={6} className="mt-2">
                                        <ThemeContainer title="Toplam Oynayan Oyuncu Sayısı" desc="Toplam masada oynayan farklı oyuncu sayısı" children={()=><>
                                        {totalPlayer}
                                        </>} />
                                    </Col>
                                    <Col md={6} className="mt-2">
                                        <ThemeContainer title="Günlük Oyuncu Sayısı" desc="Günlük masada oynayan farklı oyuncu sayısı" children={()=><>
                                        {dailyPlayer}
                                        </>} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>


                        <Col xl={6} md={12}>
                            <Container fluid className="px-0">
                                <Row>
                                    <Col md={12} className="mt-2">
                                        <ThemeContainer title="Bir Sonraki Ödeme Tarihi" desc="Hesabınıza yapılacak bir sonraki ödeme tarihi" children={()=><>
                                            {nextPaymentDate}
                                        </>} />
                                    </Col>
                                    <Col md={12} className="mt-2">
                                        <ThemeContainer title="Toplam Oynanan Ücretli Kredi" desc="Toplam masada harcanan kredi miktarı" children={()=><>
                                            {`${totalCredit} Kredi`}
                                        </>} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col> */}
                        <Col md={12} className="table-page mt-2">
                            <ThemeContainer title="" desc="" children={()=><>
                                <h1 className='analytics-text'>Aktif Masalar. </h1>
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                   
                                    <span className="ml-2 analytics-desc-text">Masaların aktiflik durumu. </span>
                                </div>
                                <Table hover className="table-content">
                                    <thead>
                                        <tr>
                                        <th className='heading-text'>Masa ({table?.trueCount} Aktif)  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            active_tables?.map((active_table,index)=>{

                                             
                                                return(
                                                    <tr key={index}>
                                                        <td className='content-text'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M6.61699 1.36206C3.71474 1.36206 1.35352 3.72329 1.35352 6.62554C1.35352 9.52779 3.71474 11.889 6.61699 11.889C9.51924 11.889 11.8805 9.52779 11.8805 6.62554C11.8805 3.72329 9.51924 1.36206 6.61699 1.36206ZM9.35628 4.86151L5.95526 8.91034C5.91795 8.95478 5.87153 8.99068 5.81914 9.0156C5.76675 9.04053 5.70961 9.05391 5.6516 9.05483H5.64477C5.58802 9.05481 5.5319 9.04286 5.48007 9.01976C5.42824 8.99665 5.38184 8.96291 5.34389 8.92072L3.88631 7.30118C3.84929 7.26192 3.8205 7.21566 3.80162 7.16511C3.78273 7.11456 3.77415 7.06074 3.77636 7.00683C3.77857 6.95291 3.79154 6.89998 3.8145 6.85115C3.83746 6.80232 3.86995 6.75857 3.91006 6.72247C3.95018 6.68638 3.9971 6.65866 4.04807 6.64096C4.09904 6.62326 4.15304 6.61592 4.20689 6.61939C4.26074 6.62285 4.31336 6.63704 4.36164 6.66113C4.40993 6.68522 4.45291 6.71872 4.48807 6.75965L5.63414 8.03301L8.7363 4.34073C8.80588 4.26028 8.90433 4.21045 9.01037 4.202C9.1164 4.19355 9.2215 4.22716 9.30294 4.29558C9.38439 4.36399 9.43564 4.46171 9.44562 4.56761C9.4556 4.67351 9.42351 4.77909 9.35628 4.86151Z" fill="#68D391"/>
                                    </svg> {active_table.name}</td>
                                                        <td className='content-text'>{dateFormat(active_table.updated_at)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </>} />
                        </Col>

                        {/* <Col md={12} className="table-page mt-2">
                            <ThemeContainer title="" desc="" children={()=><>
                                <h1 className='analytics-text'>Oyun İstatistikleri. </h1>
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M6.61699 1.36206C3.71474 1.36206 1.35352 3.72329 1.35352 6.62554C1.35352 9.52779 3.71474 11.889 6.61699 11.889C9.51924 11.889 11.8805 9.52779 11.8805 6.62554C11.8805 3.72329 9.51924 1.36206 6.61699 1.36206ZM9.35628 4.86151L5.95526 8.91034C5.91795 8.95478 5.87153 8.99068 5.81914 9.0156C5.76675 9.04053 5.70961 9.05391 5.6516 9.05483H5.64477C5.58802 9.05481 5.5319 9.04286 5.48007 9.01976C5.42824 8.99665 5.38184 8.96291 5.34389 8.92072L3.88631 7.30118C3.84929 7.26192 3.8205 7.21566 3.80162 7.16511C3.78273 7.11456 3.77415 7.06074 3.77636 7.00683C3.77857 6.95291 3.79154 6.89998 3.8145 6.85115C3.83746 6.80232 3.86995 6.75857 3.91006 6.72247C3.95018 6.68638 3.9971 6.65866 4.04807 6.64096C4.09904 6.62326 4.15304 6.61592 4.20689 6.61939C4.26074 6.62285 4.31336 6.63704 4.36164 6.66113C4.40993 6.68522 4.45291 6.71872 4.48807 6.75965L5.63414 8.03301L8.7363 4.34073C8.80588 4.26028 8.90433 4.21045 9.01037 4.202C9.1164 4.19355 9.2215 4.22716 9.30294 4.29558C9.38439 4.36399 9.43564 4.46171 9.44562 4.56761C9.4556 4.67351 9.42351 4.77909 9.35628 4.86151Z" fill="#68D391"/>
                                    </svg>
                                    <span className="ml-2 analytics-desc-text">Bu kafeye ait oyun istatistikleri. </span>
                                </div>
                                <Table hover className="table-content">
                                    <thead>
                                        <tr>
                                        <th className='heading-text'>ÜRÜNLER</th>
                                        <th className='heading-text'>TOPLAM KREDİ ({totalCredit + totalGiftCredit} ) </th>
                                        <th className='heading-text'>KAZANÇ DEĞİŞİMİ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            games?.map((game,index)=>{
                                                const currentMonthTotalCredit = game.totalCredit; // Bu ayki toplam kredi miktarı
                                                const lastMonthTotalCredit = game.lastTotalCredit; // Geçen ayki toplam kredi miktarı

                                                const increasePercentage = ((currentMonthTotalCredit - lastMonthTotalCredit) / lastMonthTotalCredit) * 100;
                                                const percent = increasePercentage == Infinity ? 100 : increasePercentage.toFixed(0);
                                                return(
                                                    <tr key={index}>
                                                        <td className='content-text'><img className="gameIcon" src={`${backend_host}gameIcons/${game.game.icon}.png`} />{game.game.name}</td>
                                                        <td className='content-text'>{game.totalCredit}</td>
                                                        <td className='content-text'>
                                                            <span className={`progress-text ${percent>0 ? "up":""} ${percent==0 ? "stable":""} ${percent<0 ? "down":""}`}>{percent}%</span>
                                                                <div className="progress custom-progress">
                                                                    <div className={`progress-bar ${percent>0 ? "up":""} ${percent==0 ? "stable":""} ${percent<0 ? "down":""}`} role="progressbar" style={{width:percent>100 ? "100%" : `${percent<0 ? -1 * percent : percent}%`}} aria-valuenow={percent>100 ? 100 : percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </>} />
                        </Col> */}
                    </Row>
                </Container>
            }
        </>
    )
}

export default Home