import React, { useEffect, useState } from "react";
import { Button, Image, Nav, Form, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/assets/logo.svg";
import logoIcon from "../../assets/images/assets/logo-icon.svg";
import {
  ArrowLeft,
  ArrowRight,
  HomeSvg,
  Menu,
  Order,
  Monitor,
} from "../../assets/images/assets/icons";
import MoneyIcon from "../../assets/images/assets/icons/money.svg";
import CreditModal from "../../components/CreditModal";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import AddGift from "../../components/AddGift";

const Sidebar = ({ collapsed, setCollapsed }) => {
  const user = useSelector((state) => state.auth.user);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isAddingCredit, setIsAddingCredit] = useState(false);
  const [creditData, setCreditData] = useState({
    phone: undefined,
    credit: undefined,
  });

  const data = {
    phone: creditData.phone,
    credit: parseInt(creditData.credit ?? 0, 10),
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`
    },
  };

  const addCreditRequest = () => {
    setIsAddingCredit(true);
    axios
      .post("/admin/gift-credit", data, config)
      .then((res) => {
        console.log("Addine credit: ", res.data);
        setIsAddingCredit(false);
        toast.success(res.data.data);
        handleClose();
      })
      .catch((e) => {
        console.error("Error when adding credit: ", e.response.data);
        setIsAddingCredit(false);
      });
  };

  useEffect(() => {
    console.log("credit data: ", creditData);
  }, [creditData.credit, creditData.phone]);

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <Nav>
        <Button
          variant="button"
          className="toggle"
          onClick={() => setCollapsed(!collapsed)}
        >
          <Image
            src={collapsed ? ArrowRight : ArrowLeft}
            className="sidebar-icon"
          />
        </Button>
        <div className="top">
          <Nav.Item>
            <NavLink to="/" className="nav-link" activeclassname="active">
              <Image src={logo} className="logo" />
              <Image src={logoIcon} className="logo-collapsed" />
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink className="gapped-side active-color">
              <Image src={Monitor} className="sidebar-icon" />
              <span className="text">Yönetim Paneli</span>
            </NavLink>
          </Nav.Item>
          <hr className="pb-3" />
        </div>
        <Nav.Item className="panel-menus">
          <NavLink to="/" className="gapped-side side-menu">
            <HomeSvg />
            <span className="text">Anasayfa</span>
          </NavLink>
          <AddGift />
          {/* <NavLink
            to="/"
            className="gapped-side side-menu mt-4"
            onClick={handleShow}
          >
            <Image src={MoneyIcon} />
            <span className="text">Kredi Yükle</span>
          </NavLink> */}

          {/* ADD CREDIT MODAL start */}
          {/* <CreditModal
            show={show}
            handleClose={handleClose}
            children={
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Telefon Numarası</Form.Label>
                  <Form.Control
                    required
                    onChange={(val) =>
                      setCreditData((prev) => ({
                        ...prev,
                        phone: val.target.value,
                      }))
                    }
                    placeholder="Numara"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Toplam Kredi</Form.Label>
                  <Form.Control
                    required
                    onChange={(val) =>
                      setCreditData((prev) => ({
                        ...prev,
                        credit: val.target.value,
                      }))
                    }
                    placeholder="Kredi"
                    type="number"
                    min={1}
                  />
                </Form.Group>
                {isAddingCredit ? (
                  <Button className="save-button">Adding...</Button>
                ) : creditData.credit && creditData.phone ? (
                  <Button
                    type="submit"
                    className="save-button"
                    onClick={addCreditRequest}>
                    Kaydet
                  </Button>
                ) : (
                  <Button
                    disabled
                    type="submit"
                    className="save-button">
                    Kaydet
                  </Button>
                )}
              </Form>
            }
          /> */}
          {/* ADD CREDIT MODAL end */}

          {/* <NavLink to="/site-bilgileri" className="gapped-side side-menu">
                    <Menu/>
                    <span className='text'>Kafe Bilgileri</span>
                </NavLink>
                <NavLink to="/masalar" className="gapped-side side-menu">
                    <Order/>
                    <span className='text'>Masalarım</span>
                </NavLink> */}
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;
