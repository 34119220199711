import { Button, Col, Container, Image, Row } from "react-bootstrap"
import { Box, Question } from "../../assets/images/assets/icons"

const Footer = () => {
    return (
        <div className='bottom'>
            <Image src={Box}  className='box' />
            <Container fluid className='bottom-container'>
                <Row className="gapped-container">
                    <Col className='center-text' md={12}>
                        <Image src={Question} className='question' />
                    </Col>
                    <Col className='center-text collapsed' md={12}>
                        <h1 className='bottom-heading'>
                            Desteğe mi ihtiyacınız var?
                        </h1>
                        <span className='bottom-text'>
                            Asistanımıza ulaşın.
                        </span>
                        <Button variant="button" className="bottom-button">
                            KODPİLOT ASİSTAN
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer