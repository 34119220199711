import { Container } from "react-bootstrap";
import NotFoundImg from "../../assets/images/assets/404.svg";

const notFound = () => {
    return (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{height:"100vh",background:"#FFF",borderRadius:25}}>
            <div className="text-center">
                <img src={NotFoundImg} alt="Bambu Games Sayfa Bulunamadı" />
            </div>
        </Container>
    );
}

export default notFound;