import { Route, Routes as Switch } from "react-router-dom";
import NotFound from "../pages/error/NotFound";
import Header from "../pages/layouts/Header";
import Footer from "../pages/layouts/Footer";
import Sidebar from "../pages/layouts/Sidebar";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { SiteInformations,Home } from "../pages";
import MobileNav from "../components/mobile-nav";
import Tables from "../pages/Tables";

const Routes = () => {
    const [collapsed,setCollapsed] = useState(false);
    const [mobileCollapse,setMobileCollapse] = useState(true)
    
    return (
        <>
        <MobileNav collapsed={mobileCollapse} setCollapsed={setMobileCollapse}  />
        <Header collapsed={collapsed} setCollapsed={setCollapsed} mobileCollapse={mobileCollapse} setMobileCollapse={setMobileCollapse} />
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Container fluid className={`content-area ${collapsed ? "collapsed":""}`}>
            <Switch>
                <Route path="/" element={<Home />} />
                <Route path="/site-bilgileri" element={<SiteInformations/>} />
                <Route path="/masalar" element={<Tables />} />
                <Route path="/anasayfa/2" element={<Home />} />
                <Route path="*"  element={<NotFound />} />
            </Switch>
        </Container>
        <Footer />
        </>
    )
}

export default Routes;