import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
name: 'alert',
initialState: {
    arr: [],
},
reducers: {
    alert: (state, action) => {
        state.arr.push(action.payload)
        return state
    },
    removeAlert: (state, action) => {
        state.arr = state.arr.filter((item,index) => index !== action.payload)
        return state
    },
}
})

export const { alert,removeAlert } = alertSlice.actions

export default alertSlice.reducer