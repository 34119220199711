import React from "react"
import { Button } from "react-bootstrap"
import { PulseLoader } from "react-spinners"

const button = (props) => {
    return (
        <Button disabled={props.loading} type={props?.type} onClick={props?.onClick} className={`button-component ${props.white ? "white":""} ${props.bordered ? "bordered":""} ${props.disableHover ?"not-hover":""} ${props.fullWidth ? "full-width":""} ${props?.centered ? "centered":""}`}>
            {
                props.loading ? <PulseLoader color={props.white ? "#000" : "#fff"} size={10} /> : props.title
            }
        </Button>
    )
}

export default button