import { Col, Container, Row, Form} from "react-bootstrap";
import ThemeContainer from "../components/ThemeContainer";
import {FileUpload, ThemeButton, ThemeInput} from "../components/index";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "../slices/alertSlice"
import { backend_host } from "../helper";
import TableCard from "../components/table-card";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";


const SiteInformations = () => {
    const [logo,setLogo] = useState(null);
    const [infos,setInfos] = useState({});
    const [tables,setTables] = useState([]);
    const [loading,setLoading] = useState(false);
    const user = useSelector((state)=>state.auth.user)
    const dispatch = useDispatch()

    const [logoLoading,setLogoLoading] = useState(false);
    const [nameLoading,setNameLoading] = useState(false);
    const [addressLoading,setAddressLoading] = useState(false);
    const [descriptionLoading,setDescriptionLoading] = useState(false);
    const [bankLoading,setBankLoading] = useState(false);

    const navigate = useNavigate();

    const reset = () => {
        document.getElementById("companyNameForm")?.reset()
        document.getElementById("companyAddressForm")?.reset()
        document.getElementById("companyDescriptionForm")?.reset()
        document.getElementById("companyBankDetailsForm")?.reset()


        axios.get("/cafes/infos",{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            console.log(res.data);
            setInfos(res.data.data)
            setTables(res.data.tables)
        })
        .catch((err)=>{
            if (err?.response?.status == 401) {
                dispatch(alert({message:"Lütfen giriş yapınız!",status:"error",show:true}))
                localStorage.removeItem("user")
                window.location.href = "/yetki/giris-yap"
            }
        })
    }

    useEffect(()=>{
        reset();// eslint-disable-line
    },[user])

    useEffect(()=>{
        setLoading(true)
        axios.get("/cafes/infos",{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setLoading(false)
            console.log(res.data);
            setInfos(res.data.data)
            setTables(res.data.tables)
        })
        .catch((err)=>{
            setLoading(false)
            if (err?.response?.status == 401) {
                dispatch(alert({message:"Lütfen giriş yapınız!",status:"error",show:true}))
                localStorage.removeItem("user")
                window.location.href = "/yetki/giris-yap"
            }
        })
    },[])


    useEffect(()=>{
        if(logo){
            const formData = new FormData();
            formData.append("file",logo)
            setLogoLoading(true)
            axios.post("cafes/infos/update-logo",formData,{
                headers: {
                    Authorization: `Bearer ${user.accessToken}`
                }
            }).then((res)=>{
                setLogoLoading(false)
                reset()
                dispatch(alert({message:"Logo Başarıyla Yüklendi!",status:"success",show:true}))
            }
            ).catch((err)=>{
                setLogoLoading(false)
                console.log(err);
                if (err?.response?.status == 400) {
                    dispatch(alert({message:err.response.data.message,status:"error",show:true}))
                }
                else{
                    dispatch(alert({message:"Logo yüklenemedi!",status:"error",show:true}))
                }
            })
        }
    },[logo])



    const submitCompanyName = (e) => {
        e.preventDefault()
        const data = new FormData(e.target);

        let arr = {}

        data.forEach((value,key)=>{
            arr[key] = value
        })
        setNameLoading(true)

        axios.post("cafes/infos/update-company-name",arr,{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setNameLoading(false)
            dispatch(alert({message:"İşletme Adı Başarıyla Güncellendi",status:"success",show:true}))
            setInfos(res.data.data)
        })
        .catch((err)=>{
            setNameLoading(false)
            if (err?.response?.status == 400) {
                dispatch(alert({message:err.response.data.message,status:"error",show:true}))
            }
        })
    }

    const submitCompanyAddress = (e) => {
        e.preventDefault()
        const data = new FormData(e.target);

        let arr = {}

        data.forEach((value,key)=>{
            arr[key] = value
        })
        setAddressLoading(true)

        axios.post("cafes/infos/update-company-address",arr,{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setAddressLoading(false)
            dispatch(alert({message:"İşletme Adresi Başarıyla Güncellendi!",status:"success",show:true}))
            setInfos(res.data.data)
        })
        .catch((err)=>{
            setAddressLoading(false)
            if (err?.response?.status == 400) {
                dispatch(alert({message:err.response.data.message,status:"error",show:true}))
            }
        })
    }
    
    const submitCompanyDetails = (e) => {
        e.preventDefault()
        const data = new FormData(e.target);

        let arr = {}

        data.forEach((value,key)=>{
            arr[key] = value
        })
        setDescriptionLoading(true)

        axios.post("cafes/infos/update-company-description",arr,{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setDescriptionLoading(false)
            dispatch(alert({message:"İşletme Açıklaması Başarıyla Güncellendi!",status:"success",show:true}))
            setInfos(res.data.data)
        })
        .catch((err)=>{
            setDescriptionLoading(false)
            if (err?.response?.status == 400) {
                dispatch(alert({message:err.response.data.message,status:"error",show:true}))
            }
        })
    }
    const submitBankDetails = (e) => {
        e.preventDefault()
        const data = new FormData(e.target);

        let arr = {}

        data.forEach((value,key)=>{
            arr[key] = value
        })
        setBankLoading(true)

        axios.post("cafes/infos/update-company-bank-details",arr,{
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((res)=>{
            setBankLoading(false)
            dispatch(alert({message:"İşletme Banka Bixlileri Güncellendi!",status:"success",show:true}))
            setInfos(res.data.data)
        })
        .catch((err)=>{
            setBankLoading(false)
            if (err?.response?.status == 400) {
                dispatch(alert({message:err.response.data.message,status:"error",show:true}))
            }
        })
    }

    const updateTableStatus = (id) => {
        // let tempTables = tables.slice();
        // tempTables = tempTables.map((table)=>{
        //     if (table.data._id == id) {
        //         table.loading = true
        //     }
        //     return table
        // })
        // setTables(tempTables)
        // axios.post("cafes/infos/update-table-status",{
        //     id:id
        // },{
        //     headers: {
        //         Authorization: `Bearer ${user.accessToken}`
        //     }
        // }).then((res)=>{
        //     dispatch(alert({message:"Masa Durumu Güncellendi!",status:"success",show:true}))
        //     reset()
        // })
        // .catch((err)=>{
        //     if (err?.response?.status == 400) {
        //         dispatch(alert({message:err.response.data.message,status:"error",show:true}))
        //     }
        // })
    }

    return (
        <>
            {
                loading?
                <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
                    <PulseLoader color={"#313860"} size={20} />
                </div>
                :
                <Container fluid>
                    <Row>
                    <Col xl={4} md={12} className="mt-4">
                        <ThemeContainer title={"İşletme Logosu"} desc={"100x100px"} children={()=><>
                            <span className="grayText mt-5">Geçerli dosya tipleri: png, jpg, jpeg, svg.</span>
                            <FileUpload loading={logoLoading} file={logo} setFile={setLogo} img={infos?.file != undefined ? `${backend_host}${infos?.file}` : false } />
                        </>} />
                    </Col>
                    <Col xl={8} md={12} className="mt-4">
                        <Container fluid>
                            <Row className="gap-3">
                                <Col md={12} className="theme-container row-layout p-0">
                                    <Form onSubmit={submitCompanyName} className="w-100" id="companyNameForm">
                                        <ThemeInput title="" Button={()=><ThemeButton loading={nameLoading} type="submit" title={"Kaydet"} />} rows={12} description="İşletme Adı*" placeholder="İşletme Adı*" type="text" name="name" defaultValue={infos?.name} />
                                    </Form>
                                </Col>
                                <Col md={12} className="theme-container row-layout p-0">
                                    <Form onSubmit={submitCompanyAddress} className="w-100" id="companyAddressForm">
                                        <ThemeInput title="" Button={()=><ThemeButton loading={addressLoading} type="submit" title={"Kaydet"} />} rows={12} description="İşletme Adresi*" placeholder="İşletme Adresi*" type="text" name="address" defaultValue={infos?.address} />
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xl={6} md={12} className="mt-4">
                        <div className="theme-container">
                            <Form onSubmit={submitCompanyDetails} className="w-100 d-flex flex-column" id="companyDescriptionForm">
                                <ThemeInput title="İşletme Hakkında Detaylar*" description="Şirketinizi tanıtacak detaylar belirtin." placeholder="İşletmenizi tanıtacak bir detay yazısı giriniz." type="text" as="textarea" rows={4} defaultValue={infos?.description} name="description" />
                                <ThemeButton loading={descriptionLoading} type="submit" title={"Kaydet"} />
                            </Form>
                        </div>
                    </Col>
                    <Col xl={6} md={12} className="mt-4">
                        <ThemeContainer rowLayout="row-layout"  title={"Banka Hesap Bixlileriniz*"} desc={"IBAN hesabının şirket hesabı olması zorunludur ve bixliler girilene kadar ödeme alınamayacaktır!"} children={()=><>
                            <Form onSubmit={submitBankDetails} className="w-100" id="companyBankDetailsForm">
                                <Container fluid className="mt-4">
                                    <Row>
                                        <Col md={12} style={{paddingLeft:0}}>
                                            <ThemeInput title="" rows={12} description="IBAN Numaranız" placeholder="IBAN Numaranızı girin" type="text" name="IBAN" defaultValue={infos?.IBAN} />
                                        </Col>
                                        <Col md={12} style={{paddingLeft:0}}>
                                            <ThemeInput title="" Button={()=><ThemeButton loading={bankLoading} type={"submit"} title={"Kaydet"} />} rows={12} description="Adınız ve Soyadınız" placeholder="Adınız ve Soyadınız" type="text" name="username" defaultValue={infos?.username} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </>} />
                    </Col>

                    <Col md={12} className="mt-4">
                        <ThemeContainer title={"Masalarınız"} desc={<>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M6.61699 1.36206C3.71474 1.36206 1.35352 3.72329 1.35352 6.62554C1.35352 9.52779 3.71474 11.889 6.61699 11.889C9.51924 11.889 11.8805 9.52779 11.8805 6.62554C11.8805 3.72329 9.51924 1.36206 6.61699 1.36206ZM9.35628 4.86151L5.95526 8.91034C5.91795 8.95478 5.87153 8.99068 5.81914 9.0156C5.76675 9.04053 5.70961 9.05391 5.6516 9.05483H5.64477C5.58802 9.05481 5.5319 9.04286 5.48007 9.01976C5.42824 8.99665 5.38184 8.96291 5.34389 8.92072L3.88631 7.30118C3.84929 7.26192 3.8205 7.21566 3.80162 7.16511C3.78273 7.11456 3.77415 7.06074 3.77636 7.00683C3.77857 6.95291 3.79154 6.89998 3.8145 6.85115C3.83746 6.80232 3.86995 6.75857 3.91006 6.72247C3.95018 6.68638 3.9971 6.65866 4.04807 6.64096C4.09904 6.62326 4.15304 6.61592 4.20689 6.61939C4.26074 6.62285 4.31336 6.63704 4.36164 6.66113C4.40993 6.68522 4.45291 6.71872 4.48807 6.75965L5.63414 8.03301L8.7363 4.34073C8.80588 4.26028 8.90433 4.21045 9.01037 4.202C9.1164 4.19355 9.2215 4.22716 9.30294 4.29558C9.38439 4.36399 9.43564 4.46171 9.44562 4.56761C9.4556 4.67351 9.42351 4.77909 9.35628 4.86151Z" fill="#68D391"/>
                                    </svg>
                                    <span className="ml-2">Toplam masa sayısı: {tables?.length}</span>
                                </div>
                                <span onClick={()=>{navigate("/masalar")}} className="pink-text pointer">Tüm masaları görüntüleyin</span>
                            </div>
                        </>} children={()=><>
                            <Container fluid className="mt-4">
                                <Row>
                                    {
                                        tables?.map((table,index)=>{
                                            if (index<4) {
                                                return(
                                                    <Col key={index} md={3} className="mt-3">
                                                        <TableCard loading={table.loading} name={table.data.name == undefined ? "DEFAULT":table.data.name} credit={table.totalCredit} id={table.data._id} update={updateTableStatus} status={table.data.status ? "Dolu":"Boş"} />
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                </Row>
                            </Container>
                        </>} />
                    </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default SiteInformations;