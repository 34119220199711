const ThemeContainer = (props) => {
    const Children = props.children
    return(
        <div className={`theme-container`}>
            <h1 className="title">
                {props.title}
            </h1>
            <span className="desc">
                {props.desc}
            </span>
            <Children />
        </div>
    )
}

export default ThemeContainer;