import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios';
import"./assets/css/style.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.baseURL = "https://api.bambugames.com.tr/api/";
// axios.defaults.baseURL = "http://127.0.0.1:4003/api/";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";


root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

