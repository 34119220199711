import { Route, Routes as Switch } from "react-router-dom";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import NotFound from "../pages/error/NotFound";

const routes = () => {
    return (
        <Switch>
            <Route path="/giris-yap" element={<Login />} />
            <Route path="/kayit-ol" element={<Register />} />
            <Route path="*"  element={<NotFound />} />
        </Switch>
    )
}

export default routes;