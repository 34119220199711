import { Button, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import MoneyIcon from "../assets/images/assets/icons/money.svg";
import CreditModal from "./CreditModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddGift = ({collapsed, setCollapsed, isMobile}) => {
  const user = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    isMobile && (
        setCollapsed("")
    )
  }
  const handleShow = () => {
    setShow(true)
    isMobile && (
        setCollapsed("collapsed")
    )
  };
  const [creditData, setCreditData] = useState({
    phone: undefined,
    credit: undefined,
  });
  const data = {
    phone: creditData.phone,
    credit: parseInt(creditData.credit ?? 0, 10),
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
      Accept: "application/json",
    },
  };
  const [isAddingCredit, setIsAddingCredit] = useState(false);
  const addCreditRequest = () => {
    setIsAddingCredit(true);
    axios
      .post("/admin/gift-credit", data, config)
      .then((res) => {
        console.log("Addine credit: ", res.data);
        setIsAddingCredit(false);
        toast.success(res.data.data);
        handleClose();
      })
      .catch((e) => {
        console.error("Error when adding credit: ", e.response.data);
        setIsAddingCredit(false);
      });
  };

  useEffect(() => {
    console.log("credit data: ", creditData);
  }, [creditData.credit, creditData.phone]);

  return (
    <>
      <NavLink
        to="/"
        className="gapped-side side-menu mt-4 mb-4"
        onClick={handleShow}
      >
        <Image src={MoneyIcon} />
        <span className="text">Kredi Yükle</span>
      </NavLink>

      <CreditModal
        show={show}
        handleClose={handleClose}
        children={
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Telefon Numarası</Form.Label>
              <Form.Control
                required
                onChange={(val) =>
                  setCreditData((prev) => ({
                    ...prev,
                    phone: val.target.value,
                  }))
                }
                placeholder="Numara"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Toplam Kredi</Form.Label>
              <Form.Control
                required
                onChange={(val) =>
                  setCreditData((prev) => ({
                    ...prev,
                    credit: val.target.value,
                  }))
                }
                placeholder="Kredi"
                type="number"
                min={1}
              />
            </Form.Group>
            {isAddingCredit ? (
              <Button className="save-button">Adding...</Button>
            ) : creditData.credit && creditData.phone ? (
              <Button
                type="submit"
                className="save-button"
                onClick={addCreditRequest}
              >
                Kaydet
              </Button>
            ) : (
              <Button disabled type="submit" className="save-button">
                Kaydet
              </Button>
            )}
          </Form>
        }
      />
    </>
  );
};

export default AddGift;
