import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../slices/authSlice";
import { alert } from "../../slices/alertSlice";
import header from "../../assets/images/assets/header.svg"
import { Col, Container, Image, Row } from "react-bootstrap";
import { Logout as LogoutIcon, MobileMenuIcon, Monitor } from "../../assets/images/assets/icons";
import logo from "../../assets/images/assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = ({collapsed,setCollapsed,mobileCollapse,setMobileCollapse}) => {
    const user = useSelector(state => state.auth.user);
    const location = useLocation();
    console.log(user);
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(alert({message:"Çıkış Başarılı!",status:"success",show:true}))
        dispatch(logout())
    }
    const [breadcrump,setBreadcrump] = useState();
    useEffect(()=>{
        switch (location.pathname) {
            case "/":
                setBreadcrump("Ana Sayfa")
                break;
            case "/site-bilgileri":
                setBreadcrump("Kafe Bilgileri")
                break;
            case "/masalar":
                setBreadcrump("Masalarım")
                break;
            default:
                break;
        }
    },[location])
    return (
        <>
        <Container fluid className="header">
            <img className="header-bg" alt="Bambu Games" src={header} />
            <Container fluid className={`header-content ${collapsed?"collapsed":""}`}>
                <Row>
                    <Col md={12} className="d-flex justify-content-between">
                        <div className="breadcrump">
                            <div>
                                <span className="breadcrump-item">Ana Sayfa</span>
                                <span className="breadcrump-item">/</span>
                                <span className="breadcrump-item">{breadcrump}</span>
                            </div>
                            {/* <h1>
                                Genel Görünüm
                            </h1> */}
                        </div>
                        <div className="d-flex gap-5">
                            {/* <div onClick={()=>window.open(frontend_host)} className="website-show-area">
                                <h1 className="header-title">Web sitenizi görüntüleyin</h1>
                                <img src={Rocket}/>
                            </div> */}
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <span className="user-span">
                                    {`Hoşgeldiniz ${user?.name} !`}
                                </span>
                                <img alt="Bambu Games" className="logoutButton" onClick={()=>Logout()} src={LogoutIcon} />
                            </div>
                        </div>
                    </Col>
                    {/* <Col md={12}>
                        
                    </Col> */}
                </Row>
            </Container>


            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Container fluid className="mobile-header">
                            <Row>
                            <Col xs={8} >
                                <Image src={logo} className="logo" />
                            </Col>
                            <Col xs={4}  className="d-flex justify-content-end">
                                <Image onClick={()=>setMobileCollapse(false)} src={MobileMenuIcon} className="mobile-menu-icon" />
                            </Col>
                            <Col xs={12} className="d-flex gap-2">
                                <Image src={Monitor} className="sidebar-icon" />
                                <span className='text'>Yönetim Paneli</span>
                            </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>


            {/* <ThemeButton title="Logout" onClick={Logout} /> */}
        </Container>
        </>
    )
}

export default Header

