import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Card = (props) => {
    return(
        <div className="card">
            <img alt="Bambu Games" src={props.icon} className="icon"/>
            <Container fluid>
                <Row className="gap-3">
                    <Col md={12} style={{paddingLeft:0}}>
                        <Container fluid>
                            <Row>
                                <Col md={12}>
                                    <h1 className="title">
                                        {props.title}
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                    <Col md={12} style={{paddingLeft:0}}>
                        <Container fluid>
                            <Row>
                                <Col className="d-flex flex-column">
                                    <h1 className="desc">
                                        {props.desc}
                                    </h1>
                                    <span className="value">
                                            {props.value}
                                    </span>
                                    
                                </Col>
                                {props.desc2 ?
                                <Col  className="d-flex flex-column">
                                    <h1 className="desc">
                                        {props.desc2}
                                    </h1>
                                    <span className="value">
                                            {props.value2}
                                    </span>
                                    
                                </Col> : null
                                }
                                 {props.linkText ?
                                <Col className="d-flex justify-content-end align-items-end more-link">
                                    <NavLink to={props.link}>
                                        {props.linkText}
                                    </NavLink>
                                </Col>: null
}
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Card;