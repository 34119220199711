import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes as Switch, useNavigate } from "react-router-dom";
import AuthRoutes from "./routes/authRoutes";
import NoAuthRoutes from "./routes/noAuthRoutes";
import { useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeAlert } from "./slices/alertSlice";

const Redirect = () =>{
  const navigate = useNavigate();
  useEffect(()=>{
    navigate("/yetki/giris-yap")
  },[])// eslint-disable-line
  return <></>
}

const RedirectHome = () =>{
  const navigate = useNavigate();
  useEffect(()=>{
    navigate("/")
  },[])// eslint-disable-line
  return <></>
}

function App() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const msg = useSelector((state) => state.alert.arr);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(isAuth);
  }, [isAuth]);


  useEffect(()=>{
      msg.map((item,index) => {
          if(item.show){
              toast(item.message,{type:item.status})
              dispatch(removeAlert(index))
          }
      })
  },[msg])

  return (
    <BrowserRouter>
      <ToastContainer />
      <Switch>
        {
          isAuth ? 
          <>
            <Route path="/yetki/*" element={<RedirectHome />} />
            <Route path="/*" element={<AuthRoutes/>} />
          </>
          :
          <>
            <Route path="/*" element={<Redirect />} />
            <Route path="/yetki/*" element={<NoAuthRoutes />} />
          </>
        }
      </Switch>
    </BrowserRouter>
  );
}

export default App;
