import { Col, Collapse, Container, Row, Table } from 'react-bootstrap';
import ThemeContainer from '../components/ThemeContainer';
import TableIcon from '../assets/images/assets/table.png';  
import { ThemeButton } from '../components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { backend_host, creditAmount } from '../helper';
import { PulseLoader } from 'react-spinners';

const Tables = () => {
    const [loading,setLoading] = useState(false);
    const user = useSelector((state)=>state.auth.user)
    const [tables, setTables] = useState([]);
    const collapseIndex = (id) => {
        let newArray = tables.map((item,i)=>{
            if (item.data._id === id) {
                item.open = !item.open;
            }
            else{
                item.open = false
            }
            return item;
        })
        setTables(newArray);
    };

    useEffect(()=>{
        setLoading(true);
        axios.get("/cafes/infos/tables",{
            headers:{
                Authorization: `Bearer ${user.accessToken}`
            }
        }).then((response)=>{
            setLoading(false);
            setTables(response.data.data);
            console.log(response.data);
        }).catch((error)=>{
            setLoading(false);
            console.log(error.response.data);
        })
    },[])
    return(
        <>
        {loading?
        <div className="d-flex justify-content-center align-items-center" style={{height:"100vh"}}>
            <PulseLoader color={"#313860"} size={20} />
        </div>
        :
        <Container fluid className='table-page'>
            <Row>
                <Col md={12} className="mt-4">
                    <ThemeContainer title={"Masalarınız"} desc={<>
                        <div className="d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M6.61699 1.36206C3.71474 1.36206 1.35352 3.72329 1.35352 6.62554C1.35352 9.52779 3.71474 11.889 6.61699 11.889C9.51924 11.889 11.8805 9.52779 11.8805 6.62554C11.8805 3.72329 9.51924 1.36206 6.61699 1.36206ZM9.35628 4.86151L5.95526 8.91034C5.91795 8.95478 5.87153 8.99068 5.81914 9.0156C5.76675 9.04053 5.70961 9.05391 5.6516 9.05483H5.64477C5.58802 9.05481 5.5319 9.04286 5.48007 9.01976C5.42824 8.99665 5.38184 8.96291 5.34389 8.92072L3.88631 7.30118C3.84929 7.26192 3.8205 7.21566 3.80162 7.16511C3.78273 7.11456 3.77415 7.06074 3.77636 7.00683C3.77857 6.95291 3.79154 6.89998 3.8145 6.85115C3.83746 6.80232 3.86995 6.75857 3.91006 6.72247C3.95018 6.68638 3.9971 6.65866 4.04807 6.64096C4.09904 6.62326 4.15304 6.61592 4.20689 6.61939C4.26074 6.62285 4.31336 6.63704 4.36164 6.66113C4.40993 6.68522 4.45291 6.71872 4.48807 6.75965L5.63414 8.03301L8.7363 4.34073C8.80588 4.26028 8.90433 4.21045 9.01037 4.202C9.1164 4.19355 9.2215 4.22716 9.30294 4.29558C9.38439 4.36399 9.43564 4.46171 9.44562 4.56761C9.4556 4.67351 9.42351 4.77909 9.35628 4.86151Z" fill="#68D391"/>
                                </svg>
                                <span className="ml-2">Toplam masa sayısı: {tables.length}</span>
                            </div>
                        </div>
                    </>} children={()=><>
                            <Table className='table-content'>
                                <thead>
                                    <tr className='table-content-tr'>
                                        <th className='heading-text'>MASALAR</th>
                                        <th className='heading-text'>MASA ADLARI</th>
                                        <th className='heading-text'>TOPLAM HARCANAN KREDİ</th>
                                        {/* <th className='heading-text'>AKTİF OLMA SÜRESİ</th> */}
                                        <th className='heading-text'>DOLU / BOŞ</th>
                                        <th className='heading-text'>DETAYLAR</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {
                                        tables.map((item,index)=>{
                                            return(
                                                <>
                                                    <tr key={`tr-${index}`} className='table-content-tr'>
                                                        <td><img src={TableIcon} /></td>
                                                        <td className='content-text'>{item.data.name == undefined ? "DEFAULT":item.data.name}</td>
                                                        <td className='content-text'>{item.totalCredit} Kredi</td>
                                                        {/* <td className='content-text'>1.200 Dakika</td> */}
                                                        <td><ThemeButton fullWidth={true} title={item.data.status ? "Dolu":"Boş"}/></td>
                                                        <td onClick={()=>collapseIndex(item.data._id)} style={{cursor:"pointer",userSelect:"none"}} className='pink-text' >Detayları görüntüleyin</td>
                                                    </tr>

                                                    <tr key={`tr2-${index}`}>
                                                        <td colSpan={6}>
                                                            <Collapse className='collapce-class' in={item.open}>
                                                                <div>
                                                                    <h1 className='analytics-text'>Oyun İstatistikleri</h1>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                        <path d="M6.61699 1.36206C3.71474 1.36206 1.35352 3.72329 1.35352 6.62554C1.35352 9.52779 3.71474 11.889 6.61699 11.889C9.51924 11.889 11.8805 9.52779 11.8805 6.62554C11.8805 3.72329 9.51924 1.36206 6.61699 1.36206ZM9.35628 4.86151L5.95526 8.91034C5.91795 8.95478 5.87153 8.99068 5.81914 9.0156C5.76675 9.04053 5.70961 9.05391 5.6516 9.05483H5.64477C5.58802 9.05481 5.5319 9.04286 5.48007 9.01976C5.42824 8.99665 5.38184 8.96291 5.34389 8.92072L3.88631 7.30118C3.84929 7.26192 3.8205 7.21566 3.80162 7.16511C3.78273 7.11456 3.77415 7.06074 3.77636 7.00683C3.77857 6.95291 3.79154 6.89998 3.8145 6.85115C3.83746 6.80232 3.86995 6.75857 3.91006 6.72247C3.95018 6.68638 3.9971 6.65866 4.04807 6.64096C4.09904 6.62326 4.15304 6.61592 4.20689 6.61939C4.26074 6.62285 4.31336 6.63704 4.36164 6.66113C4.40993 6.68522 4.45291 6.71872 4.48807 6.75965L5.63414 8.03301L8.7363 4.34073C8.80588 4.26028 8.90433 4.21045 9.01037 4.202C9.1164 4.19355 9.2215 4.22716 9.30294 4.29558C9.38439 4.36399 9.43564 4.46171 9.44562 4.56761C9.4556 4.67351 9.42351 4.77909 9.35628 4.86151Z" fill="#68D391"/>
                                                                    </svg>
                                                                    <span className="ml-2 analytics-desc-text">Bu masaya ait oyun istatistikleri.</span>
                                                                    <Table hover>
                                                                        <thead>
                                                                            <tr>
                                                                            <th className='heading-text'>OYUNLAR</th>
                                                                            {/* <th className='heading-text'>TOPLAM OYUN SÜRESİ</th> */}
                                                                            <th className='heading-text'>KREDİ</th>
                                                                            <th className='heading-text'>KAZANÇ DEĞİŞİMİ</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            item.games.map((subItem,subIndex)=>{
                                                                                
                                                                                const currentMonthTotalCredit = subItem.totalCredit; // Bu ayki toplam kredi miktarı
                                                                                const lastMonthTotalCredit = subItem.lastTotalCredit; // Geçen ayki toplam kredi miktarı

                                                                                const increasePercentage = ((currentMonthTotalCredit - lastMonthTotalCredit) / lastMonthTotalCredit) * 100;
                                                                                const percent = increasePercentage === Infinity ? 100 : increasePercentage.toFixed(0);
                                                                                return(
                                                                                    <tr>
                                                                                        <td className='content-text'><img className="gameIcon" src={`${backend_host}gameIcons/${subItem.game.icon}.png`} />{subItem.game.name}</td>
                                                                                        {/* <td className='content-text'>1.200 dakika</td> */}
                                                                                        <td className='content-text'>{subItem.totalCredit}</td>
                                                                                        <td className='content-text'>
                                                                                        <span className={`progress-text ${percent>0 ? "up":""} ${percent===0 ? "stable":""} ${percent<0 ? "down":""}`}>{percent}%</span>
                                                                                            <div className="progress custom-progress">
                                                                                                <div className={`progress-bar ${percent>0 ? "up":""} ${percent===0 ? "stable":""} ${percent<0 ? "down":""}`} role="progressbar" style={{width:percent>100 ? "100%" : `${percent<0 ? -1 * percent : percent}%`}} aria-valuenow={percent>100 ? 100 : percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Collapse>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                    </>} />
                </Col>
            </Row>
        </Container>
        }
        </>
    )
}

export default Tables;