import { Col, Container, Form, Row } from "react-bootstrap"

const Input =  (props) => {
    return (
        <Container className="input-component" fluid>
            <Row>
                <Col md={props.rows>10 ? 12 : 4}>
                    <h1 className="title">{props?.title}</h1>
                    <span className="description">{props?.description}</span>
                </Col>
                <Col md={props.rows>3 ? 12 : 8} className={`${props.rows>3 ? "mt-3":""} ${props.Button ? "row-layout":""}`}>
                    <Form.Control
                        className={`input ${props?.className}`}
                        placeholder={props?.placeholder}
                        type={props?.type}
                        as={props?.as}
                        rows={props.rows}
                        name={props?.name}
                        defaultValue={props?.defaultValue}
                    ></Form.Control>
                    {
                        props.Button && <props.Button />
                    }
                </Col>
            </Row>
        
        </Container>
    )
}

export default Input
