import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import logo from "../assets/images/assets/logo.svg";
import { Close, HomeSvg, Logout as LogoutIcon, Menu, Order } from "../assets/images/assets/icons";
import { logout } from "../slices/authSlice";
import { alert } from "../slices/alertSlice";
import { useDispatch } from "react-redux";
import AddGift from "./AddGift";
import { useEffect } from "react";

const MobileNav = (props) => {
    const {collapsed,setCollapsed} = props;

    useEffect(() => {
        console.log("MobileNav:", collapsed);
    }, [collapsed])
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(alert({message:"Çıkış Başarılı!",status:"success",show:true}))
        dispatch(logout())
    }
    return(
        <div className={`mobile-nav ${collapsed ? "collapsed":""}`}>
            <Container fluid className="pt-3">
                <Row>
                    <Col xs={6}>
                        <Image src={logo} className="logo" />
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end align-items-center" >
                        <Image onClick={()=>setCollapsed(true)} src={Close} className="close" />
                    </Col>
                    <Col xs={12} className="d-flex align-items-center mt-5">
                        <Nav className=" w-100">
                            <Nav.Item className="flex-column w-100">
                                <NavLink onClick={()=>setCollapsed(true)} to="/" className="gapped-side side-menu">
                                    <HomeSvg/>
                                    <span className='text'>Anasayfa</span>
                                </NavLink>
                                {/* <NavLink onClick={()=>setCollapsed(true)} to="/site-bilgileri" className="gapped-side side-menu">
                                    <Menu/>
                                    <span className='text'>Kafe Bilgileri</span>
                                </NavLink>
                                <NavLink onClick={()=>setCollapsed(true)} to="/masalar" className="gapped-side side-menu">
                                    <Order/>
                                    <span className='text'>Masalarım</span>
                                </NavLink> */}
                                <AddGift isMobile collapsed={collapsed} setCollapsed={setCollapsed} />
                                <NavLink  onClick={()=>{Logout()}} className="gapped-side side-menu align-items-center">
                                    <Image className="menu-icon" src={LogoutIcon} />
                                    <span className='text'>Çıkış Yapın</span>
                                </NavLink>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MobileNav;