import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
name: 'auth',
initialState: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    isAuth: localStorage.getItem('user') ? true : false,
},
reducers: {
    login: (state, action) => {
        state.user = action.payload
        state.isAuth = true
        return state
    },
    logout: (state, action) => {
        state.user = {}
        state.isAuth = false
        localStorage.removeItem('user')
        return state
    },
}
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer